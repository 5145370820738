<template>
<validation-observer ref="setttingsRules">
 <b-form @submit.prevent='submit'> 
    <b-card class="py-1">
      <!-- {{formData}} -->
      <!-- {{settings.address.en}} -->
      <h4 class="mb-2">
        {{$t('GeneralSettings')}}
      </h4>
    <b-row class="mx-1">
      <b-col cols="6">
        <label for='AppName'>
          {{$t('AppName')}}
        </label>  
        <validation-provider
          #default="{ errors }"
          rules="required"
          name='AppName'
        >
        <b-input-group >
          <b-form-input
            id="AppName"
            :placeholder="$t(formData.AppName.lang)"
            v-model='formData.AppName[formData.AppName.lang]'
            :state="errors.length > 0 ? false:null"
          />
        </b-input-group>
        <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col>
      <b-col cols="6">
        <label for='logo'>
          {{$t('logo')}}
        </label>  
        <validation-provider
          #default="{ errors }"
          rules=""
          name='logo'
        >
        <b-input-group >
          <b-form-file
            id="logo"
            v-model='formData.logo'
            :state="errors.length > 0 ? false:null"
          />
        </b-input-group>
        <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col>
      <!-- <b-col cols="4">
        <label for='favicon'>
          {{$t('favicon')}}
        </label>  
        <validation-provider
          #default="{ errors }"
          rules=""
          name='favicon'
        >
        <b-input-group >
          <b-form-file
            id="favicon"
            v-model='formData.favicon'
            :state="errors.length > 0 ? false:null"
          />
        </b-input-group>
        <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col> -->
    </b-row>

    <hr class="my-2"/>
    <h4 class="my-2"> {{$t('Other Settings')}}</h4>
    <b-row class="mx-1">
      <b-col cols='4'>
        <b-form-group
          :label="$t('email')"
          label-for='email'
        >
          <b-form-input
            type='email'
            id="email"
            v-model='formData.email'
          >
          </b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols='4'>
        <b-form-group
          :label="$t('phone')"
          label-for='phone'
        >
          <b-form-input
            type='text'
            id="phone"
            v-model='formData.phone'
          >
          </b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols='4'>
        <b-form-group
          :label="$t('address')"
          label-for='address'
        >
          <b-form-input
            type='text'
            id="address"
            v-model='formData.address'
          >
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-row>


   <hr class="my-2"/>
    <h4 class="my-2"> {{$t('Social Links')}}</h4>
    <b-row class="mx-1">
      <b-col cols='4'>
        <b-form-group
          :label="$t('facebook')"
          label-for='facebook'
        >
          <b-form-input
            type='text'
            id="facebook"
            v-model='formData.social_media_links[0].link'
          >
          </b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols='4'>
        <b-form-group
          :label="$t('instagram')"
          label-for='instagram'
        >
          <b-form-input
            type='text'
            id="instagram"
            v-model='formData.social_media_links[1].link'
          >
          </b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols='4'>
        <b-form-group
          :label="$t('youtube')"
          label-for='youtube'
        >
          <b-form-input
            type='text'
            id="youtube"
            v-model='formData.social_media_links[2].link'
          >
          </b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols='4'>
        <b-form-group
          :label="$t('twitter')"
          label-for='twitter'
        >
          <b-form-input
            type='text'
            id="twitter"
            v-model='formData.social_media_links[3].link'
          >
          </b-form-input>
        </b-form-group>
      </b-col>
      <b-col >
        <b-form-group
          :label="$t('linkedin')"
          label-for='linkedin'
        >
          <b-form-input
            type='text'
            id="linkedin"
            v-model='formData.social_media_links[4].link'
          >
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-card-footer>
      <b-button
        variant='primary'
        class="btn"
        type='submit'
        v-if='resolvePermission("settings.editSettings")'
      >
        {{$t('save')}}
      </b-button>
    </b-card-footer>
  </b-card>
 </b-form>
</validation-observer>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BFormTextarea, BInputGroup, BInputGroupAppend,
  BCard, BCardFooter, BCardHeader, BCardTitle, BFormFile
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@validations'
import {mapActions, mapGetters} from 'vuex';

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormTextarea,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BCard,BCardFooter,BCardHeader,BCardTitle, BFormFile
  },
  data() {
    return {
      required,
      formData: {
        AppName: {
          ar: '',
          en: '',
          lang: 'en'
        },        
        email: '',
        phone: '',
        address: '',
        social_media_links: [
          {name: 'facebook', link: '',},
          {name: 'instagram', link: '',},
          {name: 'youtube', link: '',},
          {name: 'twitter', link: '',},
          {name: 'linkedin', link: '',},
        ],
        logo: {},
        favicon: {},
        ShippingRules: {
          default: 0,
        },
        minProducts: 0,
      }
    }
  },

  mounted() {
    this.getSettings()
      .then(res => {
        let data = res.data.results;
        this.formData = {
          AppName: {
            ...this.formData.AppName,
            ar: data['AppName'].ar,
            en: data['AppName'].en,
          },
          email: data.email,
          phone: data.phone,
          address: data.address.en,
          social_media_links: data.social_media_links.length < 5 ? [...this.formData.social_media_links] : [...data.social_media_links] 
        }
        this.$forceUpdate()
      })
  },

  methods: {
    ...mapActions(['getSettings', 'updateSettings']),
    submit() {
    this.$refs.setttingsRules.validate()
      .then(res => {
        if(res == true) {
          if(this.formData.logo) {
            this.getBase64(this.formData.logo)
              .then(res => {
                this.formData.logo = res 
                this.updateSettings(this.formData)
                  .then(res => {
                    this.getSettings();
                  })
              })
          }else {
            this.updateSettings(this.formData)
            .then(res => {
              this.getSettings();
            })
          }
        }
      })
    },  
    changeLang(key) {
      if(this.formData[key].lang == 'ar') {
      this.formData[key].lang = 'en'
      }else {
        this.formData[key].lang = 'ar'
      }
    },
    checkEmptyLocale(key) {
      if(!this.formData[key].ar || !this.formData[key].en) {
        if(!this.formData[key].ar) {
          this.formData[key].ar = this.formData[key].en;
        }else {
          this.formData[key].en = this.formData[key].ar;
        }
      }
    }
  },

  computed: {
    ...mapGetters(['settings', 'loading']),
  }
}
</script>